<template>
  <div class="product__content">
    <tabs
      :options="{ useUrlFragment: false }"
      @clicked="tabClicked"
      nav-class="nav nav-pills"
      nav-item-class="nav-item"
      nav-item-link-class="nav-link"
      panels-wrapper-class="tab-content"
      nav-item-link-active-class="active"
    >
      <tab name="Description">
        <div v-if="product.id" v-html="product.description"></div>
        <Skeletor v-else height="200" />
      </tab>

      <tab :name="`Reviews`">
        <div class="ps-product--reviews">
          <div class="row">
            <div class="col-12 col-lg-5">
              <div class="review__box" v-if="product.id">
                <div class="product__rate">{{ product.reviews.average_rating }}</div>
                <star-rating
                  :star-size="14"
                  :rounded-corners="true"
                  :border-width="1"
                  :star-points="[23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34, 46, 19, 31, 17]"
                  :show-:rating="false"
                  :read-only="true"
                  :increment="0.5"
                  :rating="parseFloat(product.reviews.average_rating,0)"
                ></star-rating>

                <p>
                  Avg. Star Rating: <b class="text-black">({{ product.reviews.total }} reviews)</b>
                </p>
                <div class="review__progress">
                  <div class="progress-item" v-for="(p, index) in percentage" :key="index">
                    <span class="star">{{ index }} Stars</span>
                    <div class="progress">
                      <div
                        class="progress-bar bg-warning"
                        role="progressbar"
                        :style="`width: ${p}%`"
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <span class="percent">{{ p }}%</span>
                  </div>
                </div>
              </div>
              <Skeletor v-else height="200" />
            </div>
            <div class="col-12 col-lg-7">
              <div v-if="product.id">                
              <div class="review__title">Add A Review</div>
              <p class="mb-0">
                Your email will not be published. Required fields are marked <span class="text-danger">*</span>
              </p>
              <form @submit.prevent="handleSubmit">
                <div class="form-row">
                  <div class="col-12 form-group--block">
                    <div class="input__rating">
                      <label>Your rating: <span>*</span></label>
                      
                      <star-rating
                        @update:rating="setRating"
                        :star-size="14"
                        :rounded-corners="true"
                        :border-width="1"
                        :star-points="[23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34, 46, 19, 31, 17]"
                        :show-:rating="false"
                        :increment="0.5"
                      ></star-rating>
                    </div>
                    <div style="color:red;font-size:15px" v-if="$errors.has('rating')">Please choose your rating</div>
                  </div>

                  <div class="col-12 col-lg-12 form-group--block">
                    <label>Title: <span>*</span></label>
                    <input class="form-control" type="text" required v-model="FormData.title" />
                  </div>

                  <div class="col-12 col-lg-12 form-group--block">
                    <label>Name: <span>*</span></label>
                    <input class="form-control" type="text" required v-model="FormData.name" />
                  </div>

                  <div class="col-12 form-group--block">
                    <label>Review: <span>*</span></label>
                    <textarea class="form-control" v-model="FormData.comment" required></textarea>
                    <div class="invalid-feedback" v-show="$errors.has('comment')">{{ $errors.first("comment") }}</div>
                  </div>

                  <div class="col-12 form-group--block">
                    <button type="submit" class="btn ps-button ps-btn-submit">Submit Review</button>
                  </div>
                </div>
              </form>
              </div>
              <Skeletor v-else height="400" />
            </div>
          </div>

          <div class="ps--comments">
            <h5 class="comment__title">{{ reviews.length }} Comments</h5>
            <ul class="comment__list">
              <li class="comment__item" v-for="(review, index) in reviews" :key="index">
                <div class="item__avatar"><img src="@/assets/img/blogs/comment_avatar1.png" alt="alt" /></div>
                <div class="item__content">
                  <div class="item__name">{{ review.title }}</div>
                  <div class="item__date">- {{ $dateFormat(review.updated_at) }}</div>
                  <div class="item__check"><i class="icon-checkmark-circle"></i>Verified</div>
                  <div class="item__rate">
                    <star-rating
                      :star-size="14"
                      :rounded-corners="true"
                      :border-width="1"
                      :star-points="[23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34, 46, 19, 31, 17]"
                      :show-:rating="false"
                      :read-only="true"
                      :increment="0.5"
                      :rating="parseFloat(review.rating, 0)"
                    ></star-rating>
                  </div>
                  <p class="item__des">
                    {{ review.comment }}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </tab>
    </tabs>
  </div>
</template>

<script>
import { Tabs, Tab } from "vue3-tabs-component";
import StarRating from "vue-star-rating";
import { mapActions, mapGetters } from "vuex";
import "vue-skeletor/dist/vue-skeletor.css";
import { Skeletor } from "vue-skeletor";

export default {
  components: {
    Tabs,
    Tab,
    StarRating,
    Skeletor,
  },

  data() {
    return {
      FormData: {
        rating: "",
      },
      requesting: false,
    };
  },

  computed: {
    ...mapGetters("product", ["product"]),
    ...mapGetters("review", ["reviews"]),

    percentage() {
      if (this.product.reviews.percentage && this.product.reviews.percentage.length > 0) {
        return JSON.parse(this.product.reviews.percentage);
      }
      return [];
    },
  },

  methods: {
    ...mapActions("review", ["addReview"]),
    setRating(rating) {
      this.FormData.rating = rating;
    },
    handleSubmit() {
      this.FormData.product_id = this.product.id;
      this.addReview(this.FormData).then((c) => {
        this.FormData = {};
        if (c.customer) {
          this.FormData.name = c.customer.name;
        }
      });
    },
    tabClicked () {

    }
  },
};
</script>
