<template>
  <div class="product__header">
    <div class="row">
      <div class="col-12 col-lg-7 product__code">
        <star-rating
          :star-size="14"
          :rounded-corners="true"
          :border-width="1"
          :show-rating="false"
          :read-only="true"
          :rating="parseFloat(product.reviews.average_rating,0)"
        ></star-rating>
        <span class="product__review">{{reviews.length}} Customer Review</span>
        <span class="product__id d-none d-xl-block">SKU: <span>#{{product.sku}}</span></span>
      </div>
    </div>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";
import { mapGetters } from 'vuex';

export default {
  components: {
    StarRating,
  },
  computed:{
    ...mapGetters('product',['product']),
    ...mapGetters('review',['reviews'])
  }
}
</script>