<template>
  <main class="no-main">
    <div class="ps-breadcrumb">
      <div class="container-fluid-sy">
        <ul class="ps-breadcrumb__list">
          <li class="active">
            <router-link :to="{ name: 'home' }">Home</router-link>
          </li>
          <li class="active">
            <router-link :to="{ name: 'products' }">Products</router-link>
          </li>
          <li>
            <a href="javascript:void(0);">{{ product.name }}</a>
          </li>
        </ul>
      </div>
    </div>
    <section class="section--product-type section-product--default">
      <div class="container-fluid-sy">
        <!-- TitleSection section -->
        <TitleSection />

        <!-- ProductView section -->
        <ProductView />
      </div>
      <!-- FrequentlyBought section -->
      <!-- <FrequentlyBought /> -->

      <div class="container-fluid-sy">
        <!-- Description section -->
        <Description />
      </div>
    </section>

    <!-- RecentViewed section
    <RecentViewed /> -->
    
    <div v-if="related_products && related_products.length > 0">
      <!-- RelatedProducts section -->
      <RelatedProducts :products="related_products" />
    </div>
  </main>
</template>

<script>
import TitleSection from "./Components/TitleSection";
import ProductView from "./Components/ProductView";
import Description from "./Components/Description";
import RelatedProducts from "./Components/RelatedProducts";
// import RecentViewed from "./Components/RecentViewed";
import { mapActions, mapGetters } from "vuex";
import ApiService from "@/common/api.service";

export default {
  data() {
    return {
      related_products: [],
    };
  },

  computed: {
    productId: {
      get() {
        return this.$route.params.productId;
      },
    },
    ...mapGetters("product", ["products", "product", "findBySlug"]),
  },

  components: {
    TitleSection,
    ProductView,
    Description,
    RelatedProducts,
    // RecentViewed,
  },

  mounted() {
    var product = this.findBySlug(this.productId);
    if (product) {
      this.assignProduct(product);
    } else {
      this.fetchProduct(this.productId);
    }

    this.fetchReviews({ product_id: this.productId });

    this.onFetchRequest();
  },

  methods: {
    ...mapActions("product", ["fetchProduct", "assignProduct"]),
    ...mapActions("review", ["fetchReviews"]),

    async onFetchRequest() {
      var filters = {
        token: true,
      };
      const { data } = await ApiService.query("products/" + this.productId + "/related", { params: filters });
      this.related_products = data.data;
    },
  },
};
</script>
