<template>
  <div class="product__detail">
    <div class="row">
      <div class="col-12 col-lg-9">
        <div class="ps-product--detail">
          <div class="row" v-if="product.id">
            <div class="col-12 col-lg-6">
              <div class="image-container">
                  <a href="">
                    <span class="helper"></span>
                    <img :src="activeImage">
                  </a>
              </div>
              <div class="product-imgs">
                <div class="img-display" v-if="false">
                  <div class="img-showcase">
                    <div class="image-container" v-for="(img, index) in product.images" :key="index" >
                      <img :src="img.large_image_url" alt="item image" />
                    </div>
                    
                  </div>
                </div>
                <div class="img-select">
                  <div class="img-item" v-for="(img, index) in product.images" :key="index">
                    <a href="javascript:void(0)" v-bind:data-id="index" @click="setActiveImage(img.large_image_url)">
                      <img :src="img.large_image_url" alt="item image" style="height:100px" />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-lg-6">
              <h3 class="product__name">{{product.name}}</h3>
              <div class="ps-product__sale">
                <span class="price-sale">{{ productOrVariant.formated_price }}</span>
                <span class="price">{{ product.formated_regular_price }}</span>
                <span class="ps-product__off" v-if="!!percentagePrice">{{ percentagePrice }}% Off</span>
              </div>

              <div class="ps-product__avai alert__success" v-if="product.in_stock">
                Availability: <span>In stock</span>
              </div>
              <div class="ps-product__avai alert__error" v-else>
                Availability: <span>Out of stock</span>
              </div>

              <!-- Cake options -->
              <div class="mt-2" v-if="product.in_stock">
                <div class="ps-product__variable" v-for="(super_attribute, index) in superAttributes" :key="index">
                  <span>{{ super_attribute.name }}: </span>
                  <div class="ps-product__type">
                    <template v-for="(option, index) in super_attribute.options" :key="index">
                      <label v-if="option.is_enable">
                        <input class="radioOption" type="radio" v-model="
                            selectedSuperAttributes[super_attribute.code]
                          " :value="option.id" />
                        <div>{{ option.label }}</div>
                      </label>
                    </template>
                  </div>
                </div>

                <div v-if="
                    product.attribute_family &&
                    product.attribute_family.code == 'cake'
                  ">
                  <div class="customize">Message on Cake</div>
                  <div class="d-flex mt-2">
                    <textarea class="inputext col-12" id="productMessage" v-model="message"
                      placeholder="Enter the message on cate"></textarea>
                  </div>
                </div>
                <!-- End Cake options -->

                <div class="ps-product__category">
                  <span v-html="product.short_description"></span>
                </div>

                <div class="ps-product__shopping" :class="$isMobile() ? 'mobile' : ''">
                  <div class="ps-product__quantity">
                    <label>Quantity: </label>
                    <div class="def-number-input number-input safari_only">
                      <button class="minus" @click="decreaseQuantity(product.id)">
                        <i class="icon-minus"></i>
                      </button>
                      <input class="quantity" min="0" name="quantity" v-model="product.qty" type="number" />
                      <button class="plus" @click="increaseQuantity(product.id)">
                        <i class="icon-plus"></i>
                      </button>
                    </div>
                  </div>
                  <a class="ps-product__addcart ps-button" :class="$isMobile() ? 'mobile' : ''" @click="addToCartPro()">
                    <i class="icon-cart"></i>Add to cart
                  </a>
                  <a class="ps-product__icon" href="javascript:void(0)"
                    @click.prevent="addToList({ product_id: product.id })">
                    <i class="icon-heart"></i>
                  </a>

                   <router-link :to="{ name: 'products' }" class="d-none d-sm-inline theme-anchor">Continue to shopping</router-link>

                </div>

                <div class="social-media-share row">
                  <!-- <a id="fb_share" class="btn btn-outline-tertiary" @click="shareFacebook()">
                    <i class="fa fa-facebook"></i> Share
                  </a>
                  {{thisURL}} -->
                  <iframe
                  class="col-lg-3 col-sm-4 col-5"
                    :src= thisURL
                    width="68" height="20" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
                  </iframe>
                  <a class="btn btn-outline-tertiary col-lg-2 col-xs-4 col-4" id="instagram_share" href="https://www.instagram.com/suyeemarket" onclick="window.open(this.href, 'mywin','left=20,top=20,width=1000,height=1000,toolbar=1,resizable=0'); return false;">
                    <i class="fa fa-instagram"></i> Share
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="row" v-else>
            <div class="col-12 col-lg-6">
              <Skeletor height="400" />
            </div>

            <div class="col-12 col-lg-6">
              <Skeletor height="200" />
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-3">
        <div class="ps-product--extention">
          <div class="extention__block" v-if="false">
            <div class="extention__item">
              <div class="extention__icon"><i class="icon-truck"></i></div>
              <div class="extention__content">
                <b class="text-black">Free Shipping </b>apply to all orders over
                <span class="text-success">$100</span>
              </div>
            </div>
          </div>
          <div class="extention__block" v-if="false">
            <div class="extention__item">
              <div class="extention__icon"><i class="icon-leaf"></i></div>
              <div class="extention__content">
                Guranteed <b class="text-black">100% Organic </b>from natural
                farmas
              </div>
            </div>
          </div>
          <div class="extention__block" v-if="false">
            <div class="extention__item border-none">
              <div class="extention__icon">
                <i class="icon-repeat-one2"></i>
              </div>
              <div class="extention__content">
                <b class="text-black">1 Day Returns </b>if you change your mind
              </div>
            </div>
          </div>
          <div class="extention__block extention__contact">
            <p>
              <span class="text-black">Hotline Order: </span>Free 9am - 6pm
            </p>
            <h4 class="extention__phone">+1 (973) 393-1737</h4>
            <h4 class="extention__phone">+94 (768) 514 527</h4>
          </div>
          <p class="extention__footer d-block d-sm-none theme-anchor" v-if="!isAuthenticated">
            Continuous Shopping
            <router-link :to="{ name: 'register' }"> Click Here</router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import "vue-skeletor/dist/vue-skeletor.css";
  import { Skeletor } from "vue-skeletor";

  export default {
    components: {
      Skeletor,
    },

    data() {
      return {
        activeImage:'',
        thisURL:'',
        isCake: true,
        isNeedCandles: false,
        isCustomText: false,
        addCandles: false,
        selectedSuperAttributes: {},
        availableSuperAttributes: {},
        defaultVariant: {
          weight: null,
        },
        message: null,
      };
    },

    mounted() {
      const imgs = document.querySelectorAll(".img-select a");
      const imgBtns = [...imgs];
      let imgId = 1;

      imgBtns.forEach((imgItem) => {
        imgItem.addEventListener("click", (event) => {
          event.preventDefault();
          imgId = imgItem.dataset.id;
          slideImage();
        });
      });

      function slideImage() {
        const displayWidth = document.querySelector(
          ".img-showcase img:first-child"
        ).clientWidth;
        document.querySelector(".img-showcase").style.transform = `translateX(${-(imgId - 1) * displayWidth
          }px)`;
      }

      window.addEventListener("resize", slideImage);
    },

    computed: {
      ...mapGetters("product", ["product"]),
      ...mapGetters("auth", ["user", "isAuthenticated"]),
      productOrVariant() {
        var vm = this;
        var product = this.product;

        if (this.isVariants) {
          this.product.variants.forEach((variant) => {
            var check = true;
            Object.keys(vm.selectedSuperAttributes).forEach((key) => {
              if (variant[key] !== undefined) {
                if (vm.selectedSuperAttributes[key] != variant[key]) {
                  check = false;
                }
              } else {
                check = false;
              }
            });

            if (check) {
              product = variant;
            }
          });

          // if(Object.keys(vm.selectedSuperAttributes).length == 0) {
          //   if (this.product.super_attributes) {
          //     this.product.super_attributes.forEach((super_attribute) => {
          //       if(product[super_attribute.code] !== undefined) {
          //         vm.selectedSuperAttributes[super_attribute.code] = product[super_attribute.code];
          //       }
          //     });
          //   }
          // }
        }

        return product;
      },

      isVariants() {
        if (this.product.variants) {
          return !!this.product.variants.length;
        }
        return false;
      },

      percentagePrice() {
        var balancePrice =
          (100 / this.product.regular_price) *
          (this.product.regular_price - this.product.special_price);
        if (balancePrice) {
          return Number(balancePrice.toFixed(2));
        } else {
          return 0;
        }
      },

      superAttributes() {
        const attributes = new Object();
        if (this.product.super_attributes) {
          this.product.super_attributes.forEach((super_attribute) => {
            const options = new Object();
            super_attribute.options.forEach((option) => {
              options[option.id] = option;
            });

            // Check Abilablelity
            this.product.variants.forEach((variant) => {
              var variantAttrId = variant[super_attribute.code];
              if (variantAttrId !== undefined) {
                options[variantAttrId].is_enable = true;
              }
            });

            super_attribute.options = options;
            attributes[super_attribute.code] = super_attribute;
          });
        }
        return attributes;
      },
    },

    watch: {
      product() {     
        this.shareFacebook()
        let url = this.product ? this.product.images[0].large_image_url : ''
        this.setActiveImage(url)
      },

      selectedSuperAttributes: {
        handler() {
          this.superAttributes
        },
        immediate: true,
        deep: true,
      },
    },

    methods: {
      ...mapActions("product", ["increaseQuantity", "decreaseQuantity"]),
      ...mapActions("cart", ["addToCart"]),
      ...mapActions("wishlist", ["addToList"]),

      addToCartPro() {
        var options = {
          product_id: this.product.id,
          quantity: this.product.qty,
        };

        if (this.isVariants) {
          options["selected_configurable_option"] = this.productOrVariant.id;
        }

        if (this.message) {
          options["message"] = this.message;
        }

        this.addToCart(options);
      },
      shareFacebook() {
        var currentURL = document.URL;          
        var seturl = 'https://www.facebook.com/plugins/share_button.php?href='+currentURL+'&layout=button&size=small&width=68&height=20&appId'
        this.thisURL = seturl;
      },
      setActiveImage(url) {
        this.activeImage = url
      }
    },
  };
</script>

<style lang="scss" scoped>
  .image-container {
    height: 400px;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    margin: 1em 0;
    background-color: #f7f7f7;
}
.image-container .helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-left: -5px;
}
.image-container img {
    height: 100%;
}
  .img-display {
    overflow: hidden;
  }

  .img-showcase {
    display: flex;
    width: 100%;
    transition: all 0.5s ease;
  }

  .img-showcase img {
    min-width: 100%;
  }

  .img-select {
    display: flex;
  }

  .img-item {
    // margin: 0.3rem;
    margin-top: 10px;
  }

  .img-item img {
    padding: 2px;
  }

  .img-item:nth-child(1),
  .img-item:nth-child(2),
  .img-item:nth-child(3) {
    margin-right: 0;
  }

  .img-item:hover {
    opacity: 0.8;
  }

  .inputext {
    border: 1px solid rgb(223, 221, 221);
    border-radius: 5px;
    height: 30px;
  }

  .customize {
    color: #02b801;
    font-weight: bold;
    font-size: 14px;
  }

  .customize:hover {
    color: #ff7200;
    text-decoration: underline;
    cursor: pointer;
  }

  [type="radio"] {
    position: absolute;
    display: none;
  }

  [type="radio"]+div {
    cursor: pointer;
    padding: 3px 15px 3px 15px;
    border-width: 1px;
    border-style: solid;
    border-color: #ff7200;
    margin: 5px;
    border-radius: 2px;
  }

  [type="radio"]:checked+div {
    border-radius: 2px;
    background-color: #ff7200;
    color: white;
  }

  .fa-close {
    cursor: pointer;
  }
  .ps-product__shopping.mobile{
    display: flex;
    justify-content: space-evenly;
  }
  .ps-product__addcart.ps-button.mobile{
    margin-top: 25px !important;
  }
</style>