<template>
  <div class="section-flashdeal--default ps-home--block">
    <div class="container-fluid-sy">
      <h3 class="product__name">Related Products</h3>
      <div class="flashdeal--content">
        <carousel
          :itemsToShow="itemsToShow"
          :itemsToScroll="1"
          :autoplay="6000"
          :transition="1000"
          :wrapAround="false"
          snap-align="start"
          :breakpoints="breakpoints"
          class="owl-carousel owl-loaded owl-drag"
        >
          <slide v-for="(product, index) in products" :key="index" class="carousel-flash-deals mg-r-10">
            <product-default :product="product" />
          </slide>

          <template #addons>
            <div class="owl-nav">
              <navigation />
            </div>
          </template>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import ProductDefault from '../../Components/ProductDefault.vue';

export default {
  props: {
    products: {
      type: Array,
    },
  },

  components: {
    Carousel,
    Slide,
    Navigation,
    ProductDefault,
  },

  data() {
    return {
      itemsToShow: 6,
      breakpoints: {
        0: {
          itemsToShow: 1,
          snapAlign: 'start',
        },
        // 300px and up
        300: {
          itemsToShow: 2,
          snapAlign: 'start',
        },
        // 600px and up
        600: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
        // 800px and up
        800: {
          itemsToShow: 4,
          snapAlign: 'start',
        },
        // 1024 and up
        1024: {
          itemsToShow: 6,
          snapAlign: 'start',
        },
      },
    };
  },

  mounted() {
    
  },

  unmounted() {
    
  },

  methods: {
  },
};
</script>

<style scoped>
.carousel-flash-deals {
  display: inline-block;
  margin-right: 10px;
}
</style>
